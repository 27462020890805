import {SortingOptions} from './types/galleryTypes';

export function hasScrollReachedElementBottom(
  elementRect: DOMRect,
  bodyRect: DOMRect,
  currentScrollTop: number,
  windowHeight: number,
): boolean {
  const elementTop = elementRect.top - bodyRect.top;
  const maxScroll = elementRect.height + elementTop;
  const currScrollBottom = currentScrollTop + windowHeight;
  const bottomElementScrollRatio = currScrollBottom / maxScroll;

  return bottomElementScrollRatio >= 1;
}

export function convertToSortingOption(object: any): SortingOptions {
  return {
    isDisabled: false,
    isSelectable: true,
    id: object.id,
    value: object.name,
  };
}

export function convertToSortingOptions(arr: any[]): SortingOptions[] {
  return arr.map(convertToSortingOption);
}
