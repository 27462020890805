import * as React from 'react';
import {useStyles} from '@wix/tpa-settings/react';
import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {EtherIcon} from '../../../../../../assets/icons/ether';
import stylesParams from '../../../../stylesParams';
import s from './AssetItemContent.scss';

const AssetItemDetails: React.FC<{digitalAsset: DigitalAsset}> = ({digitalAsset: {priceData}}) => {
  const styles = useStyles();

  const showItemPrice = styles.get(stylesParams.itemPrice);

  if (!showItemPrice) {
    return null;
  }

  return (
    <div className={s.assetItemDetails}>
      <EtherIcon />
      <span className={s.priceValue}>{priceData?.price}</span>
      <span className={s.srOnly}>Ether</span>
    </div>
  );
};

export const AssetItemsContent: React.FC<{digitalAsset: DigitalAsset}> = (props) => {
  const {digitalAsset} = props;
  const {name} = digitalAsset;

  const styles = useStyles();

  const showItemName = styles.get(stylesParams.showItemName);
  const showItemPrice = styles.get(stylesParams.itemPrice);

  if (!showItemName && !showItemPrice) {
    return null;
  }

  return (
    <div className={s.assetItemContent}>
      {showItemName ? (
        <div className={`${s.assetNameContainer} ${showItemPrice ? s.rowSeparator : ''}`}>
          <h2 className={s.assetName} title={name as string}>
            {name}
          </h2>
        </div>
      ) : (
        ''
      )}
      <AssetItemDetails digitalAsset={digitalAsset} />
    </div>
  );
};
