import * as React from 'react';
import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {hasScrollReachedElementBottom} from '../../../../../utils';
import {AssetsListGrid} from './AssetsListGrid';
import {ThreeDotsLoader} from 'wix-ui-tpa';
import s from './AssetsList.scss';
import {GalleryContext} from '../../store/GalleryContext';
import {EmptyState} from '../EmptyState/EmptyState';

export interface ProductListGridProps {
  items: DigitalAsset[];
}

const Loader = () => {
  return (
    <div className={s.loader}>
      <ThreeDotsLoader />
    </div>
  );
};

const onScroll = async (componentRef: React.RefObject<HTMLDivElement>, getNextPage: Function) => {
  if (!componentRef?.current) {
    return;
  }

  const assetListElementRect = componentRef.current.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  const hasScrollReachedProductsListBottom = hasScrollReachedElementBottom(
    assetListElementRect,
    bodyRect,
    document.documentElement.scrollTop,
    window.innerHeight,
  );

  if (hasScrollReachedProductsListBottom) {
    getNextPage();
  }
};

export const AssetsList: React.FC<ProductListGridProps> = ({items}) => {
  const {isHasNextPage, getNextPage, isCurrentlyLoading} = React.useContext(GalleryContext);

  const componentRef = React.useRef<HTMLDivElement>(null);

  const shouldLoadMore = !isCurrentlyLoading && isHasNextPage;

  const handleOnScroll = React.useCallback(
    (event) => {
      getNextPage && shouldLoadMore && onScroll(componentRef, getNextPage);
    },
    [shouldLoadMore, getNextPage],
  );

  React.useEffect(() => {
    const SCROLL_EVENT_NAME = 'scroll';
    window && window.addEventListener(SCROLL_EVENT_NAME, handleOnScroll);

    return () => {
      window && window.removeEventListener(SCROLL_EVENT_NAME, handleOnScroll);
    };
  }, [handleOnScroll]);

  if (!items.length) {
    return isCurrentlyLoading ? <Loader /> : <EmptyState />;
  }

  return (
    <>
      <section ref={componentRef}>
        <AssetsListGrid items={items} />
      </section>
      {isCurrentlyLoading && <Loader />}
    </>
  );
};
