import {useSettings} from '@wix/tpa-settings/react';
import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
import {SortingOptionsIds} from '../../../../../../types/galleryTypes';
import settingsParams from '../../../../settingsParams';
import {GalleryContext} from '../../../store/GalleryContext';
import {classes} from './Sort.st.css';

export const Sort: React.FC = () => {
  const [selectedOption, setSelectedOption] = React.useState<SortingOptionsIds | undefined>(undefined);

  const {sortingOptions, sortAssetsById} = React.useContext(GalleryContext);

  const settings = useSettings();

  if (!sortingOptions) {
    return null;
  }

  const onChange = (option: DropdownOptionProps) => {
    const {id} = option;
    sortAssetsById && sortAssetsById(id);
    setSelectedOption(id as SortingOptionsIds);
  };

  return (
    <Dropdown
      className={classes.sort}
      placeholder={settings.get(settingsParams.sortPlaceholder)}
      options={sortingOptions}
      onChange={onChange}
      initialSelectedId={selectedOption}
    />
  );
};
