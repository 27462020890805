import React from 'react';
import {FloatingDropdown} from 'wix-ui-tpa';
import {GalleryContext} from '../../../store/GalleryContext';
import {classes} from './CollectionsFilters.st.css';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export const CollectionsFilters: React.FC = () => {
  const {collectionsOptions, filterAssetsByCollectionId, collectionOptionFilteredId} = React.useContext(GalleryContext);
  const {isMobile} = useEnvironment();

  if (!collectionsOptions) {
    return null;
  }

  const onChange = (option: any) => {
    const {id} = option;
    filterAssetsByCollectionId && filterAssetsByCollectionId(id);
  };

  return (
    <FloatingDropdown
      label=""
      placeholder="Filter By Collection"
      value={collectionOptionFilteredId}
      className={classes.collectionsFilters}
      options={collectionsOptions}
      onChange={onChange}
      displayBlock={isMobile}
    />
  );
};
