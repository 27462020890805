import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings';

export type IStylesParams = {
  showItemName: StyleParamType.Boolean;
  itemPrice: StyleParamType.Boolean;
  showBuyNowButton: StyleParamType.Boolean;
  showBuyNowButtonOnHoverOnly: StyleParamType.Boolean;
  backgroundColor: StyleParamType.Color;
  productBackgroundColor: StyleParamType.Color;
  filtersColor: StyleParamType.Color;
  filtersTextFont: StyleParamType.Font;
  filtersTextColor: StyleParamType.Color;
  filtersBottomMargin: StyleParamType.Number;
  sortingBorderColor: StyleParamType.Color;
  sortingBorderWidth: StyleParamType.Number;
  sortingCornerRadius: StyleParamType.Number;
  emptyStateTextFont: StyleParamType.Font;
  emptyStateTextColor: StyleParamType.Color;
  productPriceFont: StyleParamType.Font;
  productPriceColor: StyleParamType.Color;
  productNameFont: StyleParamType.Font;
  productNameColor: StyleParamType.Color;
  productBorderColor: StyleParamType.Color;
  productBorderWidth: StyleParamType.Number;
  productCornerRadius: StyleParamType.Number;
  imageProductBorderColor: StyleParamType.Color;
  imageProductBorderWidth: StyleParamType.Number;
  imageProductCornerRadius: StyleParamType.Number;
  buyNowButtonColor: StyleParamType.Color;
  buyNowButtonCornerRadius: StyleParamType.Number;
  buyNowButtonBorderColor: StyleParamType.Color;
  buyNowButtonBorderWidth: StyleParamType.Number;
  buyNowTextFont: StyleParamType.Font;
  buyNowTextColor: StyleParamType.Color;
  gridProductPadding: StyleParamType.Number;
  gridProductInfoPadding: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  gridProductPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  gridProductInfoPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  showBuyNowButtonOnHoverOnly: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => {
      return false;
    },
  },
  showBuyNowButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  itemPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showItemName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  productBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  productBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  productCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  imageProductBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  imageProductBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  imageProductCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  filtersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  filtersTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  filtersTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  filtersBottomMargin: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 20;
    },
  },
  sortingBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sortingBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 1;
    },
  },
  sortingCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  productNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: {bold: true, italic: false, underline: false},
    }),
  },
  emptyStateTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-18'),
  },
  emptyStateTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 24,
    }),
  },
  productPriceColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  productPriceFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  buyNowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buyNowTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buyNowTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  buyNowButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buyNowButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
  buyNowButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => {
      return 0;
    },
  },
});
