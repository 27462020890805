import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';
import applicationJson from '../../../.application.json';
import {HoverTypeValues} from '../../types/galleryTypes';

export type ISettingsParams = {
  greetingsText: SettingsParamType.Text;
  buyNowText: SettingsParamType.Text;
  soldBuyNowText: SettingsParamType.Text;
  sortPlaceholder: SettingsParamType.Text;
  emptyStateMessage: SettingsParamType.Text;
  hoverAnimationType: SettingsParamType.Text;
  showSortingOptions: SettingsParamType.Boolean;
  showCollectionsFilter: SettingsParamType.Boolean;
  showWixCollections: SettingsParamType.Boolean;
  sortingOptionRecentlyAdded: SettingsParamType.Boolean;
  sortingOptionPriceHighToLow: SettingsParamType.Boolean;
  sortingOptionPriceLowToHigh: SettingsParamType.Boolean;
  gridColumns: SettingsParamType.Number;
  gridRows: SettingsParamType.Number;
  gridColumnsSpacing: SettingsParamType.Number;
  gridRowsSpacing: SettingsParamType.Number;
};

export default createSettingsParams<ISettingsParams>({
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => `${t('app.settings.defaults.greetingsPretext')} ${applicationJson.appName}`,
    inheritFromAppScope: true,
  },
  buyNowText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => `${t('app.settings.defaults.buyNowText')}`,
    inheritFromAppScope: true,
  },
  soldBuyNowText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => `${t('app.settings.defaults.soldBuyNowText')}`,
    inheritFromAppScope: true,
  },
  sortPlaceholder: {
    type: SettingsParamType.Text,
    getDefaultValue: () => `Sort By`,
  },
  emptyStateMessage: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => `${t('app.settings.defaults.emptyStateMessage')}`,
  },
  hoverAnimationType: {
    type: SettingsParamType.Text,
    getDefaultValue: () => HoverTypeValues.None,
  },
  showSortingOptions: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showCollectionsFilter: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  showWixCollections: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  sortingOptionRecentlyAdded: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  sortingOptionPriceHighToLow: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  sortingOptionPriceLowToHigh: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => {
      return true;
    },
  },
  gridColumns: {
    type: SettingsParamType.Number,
    getDefaultValue: ({isMobile}) => {
      return isMobile ? 1 : 4;
    },
  },
  gridRows: {
    type: SettingsParamType.Number,
    getDefaultValue: () => {
      return 3;
    },
  },
  gridColumnsSpacing: {
    type: SettingsParamType.Number,
    getDefaultValue: ({isMobile}) => {
      return isMobile ? 24 : 32;
    },
  },
  gridRowsSpacing: {
    type: SettingsParamType.Number,
    getDefaultValue: ({isMobile}) => {
      return isMobile ? 24 : 32;
    },
  },
});
