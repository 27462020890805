import {useSettings} from '@wix/tpa-settings/react';
import React from 'react';
import settingsParams from '../../../settingsParams';
import {CollectionsFilters} from './CollectionsFilters/CollectionsFilters';
import s from './GalleryActions.scss';
import {Sort} from './Sort/Sort';

export const GalleryActions = () => {
  const settings = useSettings();
  const showCollectionsFilter = settings.get(settingsParams.showCollectionsFilter);
  const showSortingOptions = settings.get(settingsParams.showSortingOptions);

  return (
    <div className={s.desktopContainer}>
      {showCollectionsFilter && (
        <div className={s.dropdownContainer}>
          <CollectionsFilters />
        </div>
      )}
      {showSortingOptions && (
        <div className={s.dropdownContainer}>
          <Sort />
        </div>
      )}
    </div>
  );
};
