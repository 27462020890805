import React from 'react';

export const EtherIcon = ({className}: {className?: string}) => (
  <div style={{display: 'flex', alignItems: 'center'}} className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
      <g clip-path="url(#clip0_1408_409274)">
        <path
          d="M4.95665 0.201263L4.85156 0.558517V10.9252L4.95665 11.0301L9.76873 8.18566L4.95665 0.201263Z"
          fill="#343434"
        />
        <path d="M4.9566 0.201263L0.144531 8.18566L4.9566 11.0301V5.99846V0.201263Z" fill="#8C8C8C" />
        <path
          d="M4.95669 11.9412L4.89746 12.0134V15.7062L4.95669 15.8792L9.77163 9.0982L4.95669 11.9412Z"
          fill="#3C3C3B"
        />
        <path d="M4.9566 15.8792V11.9412L0.144531 9.09818L4.9566 15.8792Z" fill="#8C8C8C" />
        <path d="M4.95703 11.03L9.76905 8.18569L4.95703 5.99846V11.03Z" fill="#141414" />
        <path d="M0.144531 8.18569L4.95654 11.03V5.99846L0.144531 8.18569Z" fill="#393939" />
      </g>
      <defs>
        <clipPath id="clip0_1408_409274">
          <rect width="9.96226" height="16" fill="white" transform="translate(0.0185547)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);
