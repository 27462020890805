import React, {FC} from 'react';
import {WidgetProps, ExperimentsProvider} from '@wix/yoshi-flow-editor';
import {st, classes} from './Widget.st.css';
import GalleryApp from './GalleryApp/GalleryApp';
import {ControllerProps} from '../../../types/galleryTypes';
import {GalleryContext} from './store/GalleryContext';

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  return (
    <div className={st(classes.root, {})} data-hook="Gallery-wrapper">
      <ExperimentsProvider>
        <GalleryContext.Provider value={props}>
          <GalleryApp />
        </GalleryContext.Provider>
      </ExperimentsProvider>
    </div>
  );
};

export default Widget;
