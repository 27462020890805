import {useSettings} from '@wix/tpa-settings/react';
import React from 'react';
import settingsParams from '../../../settingsParams';
import s from './EmptyState.scss';

export const EmptyState = () => {
  const settings = useSettings();

  return (
    <>
      <div className={s.titleSub}>
        <h3>{settings.get(settingsParams.emptyStateMessage)}</h3>
      </div>
    </>
  );
};
