import React from 'react';
import {ControllerProps} from '../../../../types/galleryTypes';

const noop = () => {};

export const GalleryContext = React.createContext<Partial<ControllerProps>>({
  instance: '',
  metaSiteId: '',
  host: undefined,
  isMobile: false,
  digitalAssets: undefined,
  sortingOptions: [],
  collectionsOptions: [],
  sortAssetsById: noop,
  navigateToAssetPage: noop,
  filterAssetsByCollectionId: noop,
  getNextPage: noop,
  copyToClipboard: noop,
  isHasNextPage: false,
  isCurrentlyLoading: true,
});
