import React from 'react';
import {Image, ImageResizeOptions} from 'wix-ui-tpa';
import {classes} from './AssetItemImage.st.css';

export const AssetItemImage = ({imageId}: {imageId?: string}) => {
  const imgSource = `https://static.wixstatic.com/media/${imageId}`;

  return (
    <div className={classes.mediaWrapper}>
      <Image
        className={classes.image}
        src={imgSource}
        resize={ImageResizeOptions.cover}
        fluid={true}
        aspectRatio="square"
        focalPoint={'center' as any}
        alt="product purchased"
      />
    </div>
  );
};
