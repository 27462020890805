import {useEffect, useState} from 'react';

export const useIsMounted = () => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);

  return didMount;
};
