import React from 'react';
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor';
import {AssetsList} from '../Components/AssetsList/AssetsList';
import {GalleryContext} from '../store/GalleryContext';
import {GalleryActions} from '../Components/GalleryActions/GalleryActions';
import {useSettings} from '@wix/tpa-settings/react';
import s from './GalleryApp.scss';
import settingsParams from '../../settingsParams';
import {PurchaseDialog} from '@wix/crypto-nft-purchase-dialog';

const GalleryApp = () => {
  const {
    digitalAssets,
    onCloseModalClicked,
    purchasedAsset,
    purchasedAssetCollection,
    isModalOpened,
    instance,
    metaSiteId,
    copyToClipboard,
    host,
    isSsr,
    lastConnectedWallet,
    setLastConnectedWalletKey,
    removeLastConnectedWalletKey,
  } = React.useContext(GalleryContext);
  const {isMobile} = useEnvironment();
  const {experiments} = useExperiments();
  const {t} = useTranslation();
  const settings = useSettings();
  const showGalleryActions =
    settings.get(settingsParams.showCollectionsFilter) || settings.get(settingsParams.showSortingOptions);

  return (
    <div className={s.root} data-hook="gallery-wrapper">
      <main>
        {showGalleryActions && <GalleryActions />}
        <AssetsList items={digitalAssets !== undefined ? digitalAssets : []} />
        {!isSsr ? (
          <PurchaseDialog
            t={t}
            isMobile={isMobile}
            isDialogOpen={isModalOpened || false}
            onCloseDialog={() => {
              onCloseModalClicked && onCloseModalClicked();
            }}
            copyToClipboard={(s: string) => copyToClipboard && copyToClipboard(s)}
            digitalAsset={purchasedAsset}
            compId={host?.id || ''}
            instance={instance || ''}
            metaSiteId={metaSiteId || ''}
            collection={purchasedAssetCollection}
            lastConnectedWallet={lastConnectedWallet || ''}
            setLastConnectedWalletKey={setLastConnectedWalletKey as any}
            removeLastConnectedWalletKey={removeLastConnectedWalletKey as any}
            isBlockchainDisabled={experiments.enabled('specs.crypto.MockBlockchain')}
          />
        ) : undefined}
      </main>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default GalleryApp;
