import * as React from 'react';
import {classes} from './BuyNowButton.st.css';
import {Button} from 'wix-ui-tpa';
import settingsParams from '../../../../settingsParams';
import {useSettings, useStyles} from '@wix/tpa-settings/react';
import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {GalleryContext} from '../../../store/GalleryContext';
import stylesParams from '../../../../stylesParams';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export const BuyNowButton: React.FC<{digitalAsset: DigitalAsset; isHovered: boolean}> = (props) => {
  const {digitalAsset, isHovered} = props;
  const {id, quantity} = digitalAsset;
  const {isMobile} = useEnvironment();
  const settings = useSettings();
  const styles = useStyles();

  const {onBuyAssetClicked} = React.useContext(GalleryContext);

  if (!styles.get(stylesParams.showBuyNowButton)) {
    return null;
  }

  const isSold = quantity?.total === quantity?.sold;

  const onClick = async () => onBuyAssetClicked && onBuyAssetClicked(id);
  const isHidden = !isMobile && styles.get(stylesParams.showBuyNowButtonOnHoverOnly) && !isHovered;
  const buttonText = isSold ? settings.get(settingsParams.soldBuyNowText) : settings.get(settingsParams.buyNowText);

  return (
    <Button
      disabled={isSold}
      onClick={onClick}
      className={`${classes.buyNowButton} ${isSold ? classes.soldBuyNowButton : ''} ${isHidden ? classes.hidden : ''}`}>
      {buttonText}
    </Button>
  );
};
