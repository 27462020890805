import * as React from 'react';
import s from './AssetItem.scss';
import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {GalleryContext} from '../../../store/GalleryContext';
import {AssetItemsContent} from './AssetItemContent';
import {BuyNowButton} from './BuyNowButton';
import {AssetItemImage} from './AssetItemImage';
import cx from 'classnames';
import {useStyles, useSettings} from '@wix/tpa-settings/react';
import stylesParams from '../../../../stylesParams';
import settingsParams from '../../../../settingsParams';
import {HoverTypeValues} from '../../../../../../types/galleryTypes';
import {useState, useEffect} from 'react';
import {useIsMounted} from '../../../../../../hooks/useIsMounted';

export const AssetItem: React.FC<{digitalAsset: DigitalAsset}> = (props) => {
  const {digitalAsset} = props;
  const {mediaItem, id} = digitalAsset;
  const [animationPreview, setAnimationPreview] = useState(false);
  const settings = useSettings();
  const hoverAnimationType = settings.get(settingsParams.hoverAnimationType);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted && hoverAnimationType !== HoverTypeValues.None) {
      setAnimationPreview(true);
      setTimeout(() => setAnimationPreview(false), 1000);
    }
  }, [hoverAnimationType]);

  const {navigateToAssetPage, assetPageUrl} = React.useContext(GalleryContext);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleAssetItemClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    navigateToAssetPage && navigateToAssetPage(assetPageLink);
  };

  const assetPageLink = `${assetPageUrl}/${id}`;

  const classNames = cx(s.assetItemContainer, {
    [s.zoomAnimation]: hoverAnimationType === HoverTypeValues.Zoom,
    [s.shadowAnimation]: hoverAnimationType === HoverTypeValues.Shadow,
    [s.preview]: animationPreview,
  });

  return (
    <div
      className={classNames}
      role="group"
      aria-label="asset item"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <a className={s.assetPageLink} href={assetPageLink} onClick={handleAssetItemClick}>
        <AssetItemImage imageId={mediaItem?.image?.id} />
        <AssetItemsContent digitalAsset={digitalAsset} />
      </a>
      <BuyNowButton isHovered={isHovered} digitalAsset={digitalAsset} />
    </div>
  );
};
