import * as React from 'react';
import {Grid} from 'wix-ui-tpa';
import {AssetItem} from './AssetItem/AssetItem';
import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import s from './AssetsListGrid.scss';
import {useSettings} from '@wix/tpa-settings/react';
import settingsParams from '../../../settingsParams';

export interface ProductListGridProps {
  items: DigitalAsset[];
}

export const AssetsListGrid: React.FC<ProductListGridProps> = ({items}) => {
  const settings = useSettings();

  return (
    <Grid
      maxColumns={settings.get(settingsParams.gridColumns)}
      rowGap={settings.get(settingsParams.gridRowsSpacing)}
      columnGap={settings.get(settingsParams.gridColumnsSpacing)}
      className={s.root}>
      {items.map((asset) => (
        <Grid.Item key={asset.id} colSpan={1} rowSpan={1}>
          <AssetItem digitalAsset={asset} key={asset.id} />
        </Grid.Item>
      ))}
    </Grid>
  );
};
