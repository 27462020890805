import {DigitalAsset} from '@wix/ambassador-crypto-nft-v1-digital-asset/types';
import {Collection} from '@wix/ambassador-crypto-nft-v1-collection/build/es/types';
import {IHostProps} from '@wix/yoshi-flow-editor';

export interface SortingOptions {
  isDisabled: boolean;
  isSelectable: boolean;
  id: string;
  value: string;
}

export type ControllerProps = {
  assetPageUrl: string;
  instance: string;
  metaSiteId: string;
  isMobile: boolean;
  host: IHostProps;
  isSsr: boolean;
  isModalOpened: boolean;
  purchasedAsset: DigitalAsset | undefined;
  digitalAssets: DigitalAsset[] | undefined;
  purchasedAssetCollection: Collection;
  collectionOptionFilteredId: string | undefined;
  collectionsOptions: SortingOptions[];
  sortingOptions: SortingOptions[];
  isHasNextPage: boolean;
  isCurrentlyLoading: boolean;
  getNextPage: Function;
  sortAssetsById: Function;
  navigateToAssetPage: Function;
  filterAssetsByCollectionId: Function;
  onBuyAssetClicked: Function;
  onCloseModalClicked: Function;
  copyToClipboard: Function;
  storageGetItem: Function;
  storageSetItem: Function;
  storageRemoveItem: Function;
  lastConnectedWallet: string;
  setLastConnectedWalletKey: Function;
  removeLastConnectedWalletKey: Function;
};

export enum SortingOptionsIds {
  createdDate = 'createdDate',
  priceLowToHigh = 'priceLowToHigh',
  priceHighToLow = 'priceHighToLow',
}

export enum HoverTypeValues {
  None = 'none',
  Zoom = 'zoom',
  Shadow = 'shadow',
}
